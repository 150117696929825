var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SelectColumnsButton, TopToolbar, useListContext, useDataProvider, useNotify, useRefresh, useTranslate, } from "react-admin";
import { GenderMuiChoices, EducationLevelMuiChoices, } from "../../subjectPage/Params";
import { Divider, Typography, Grid, Button, } from "@mui/material";
// import MobileGrid from './MobileGrid';
import { general } from "../../utils/componentWizard";
import FilterList from "./FilterList";
import TableDatagrid from "./Table";
import { NORMAL_GRID_SPACE, } from "../../styles/styleConstants.data";
import "../../styles/global.module.css";
import PaginationControlled from '../../utils/Pagination';
import { EducationLevelInArray } from "../../coreData/MetaData";
var ListActions = function () { return (_jsx(TopToolbar, { children: _jsx(SelectColumnsButton, {}) })); };
var ExportList = function (_a) {
    var props = _a.props, data = _a.data, setData = _a.setData, isLoading = _a.isLoading, setIsLoading = _a.setIsLoading, filters = _a.filters, setFilters = _a.setFilters, previewExport = _a.previewExport, dataGridHeader = _a.dataGridHeader, setDataGridHeader = _a.setDataGridHeader, acessControlList = _a.acessControlList, selectedAll = _a.selectedAll, setSelectedAll = _a.setSelectedAll;
    var dataProvider = useDataProvider();
    // const resource = useResourceContext(props)
    var resource = props;
    var notify = useNotify();
    var refresh = useRefresh();
    var navigate = useNavigate();
    // const listContext = useListContext();
    var translate = useTranslate();
    var allListContext = useListContext();
    var _b = useState(false), hasBulkSelect = _b[0], setHasBulkSelect = _b[1]; //check if all data is selected from bulk selection
    var _c = useState({
        gender: GenderMuiChoices,
        educationLevel: EducationLevelMuiChoices,
    }), params = _c[0], setParams = _c[1];
    var educationLevelArray = EducationLevelInArray;
    var _d = useState(false), selectedColumn = _d[0], setSelectedColumn = _d[1];
    var _e = useState(), dialogData = _e[0], setDialogData = _e[1];
    // for left panel
    var _f = useState(), selectedRow = _f[0], setSelectedRow = _f[1];
    // const { sort } = useListController();
    // document.body.style.zoom = "100%";
    // pagination
    var _g = React.useState(10), total = _g[0], setTotal = _g[1]; // total
    var _h = React.useState(1), count = _h[0], setCount = _h[1]; // page: <- 1 2 3 ... -> //Math.ceil(total / rowsPerPage)
    var _j = React.useState(10), rowsPerPage = _j[0], setRowsPerPage = _j[1];
    //[1021] [back] wip translate keys
    var paramsWithTranslate = function () {
        // update the params for translation 
        //gender
        var newParams = __spreadArray([], params, true);
        newParams['gender'][0].label = translate("pages.params.subjects.M");
        newParams['gender'][1].label = translate("pages.params.subjects.F");
        newParams['gender'][2].label = translate("pages.params.subjects.notDisclose");
        var translatedYear = translate("pages.params.subjects.yrs");
        newParams['gender'][0].label = translate("pages.params.subjects.unkown");
        newParams['gender'].map(function (el, idx) { el.label = el.label.replace("yrs", translatedYear); });
    };
    useEffect(function () {
        var _a;
        var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
        if (sessonStorageJSON) {
            setFilters(__assign(__assign({}, filters), sessonStorageJSON));
        }
        sessionStorage.setItem("".concat(window.location.pathname, "_filter"), JSON.stringify(__assign(__assign({}, filters), sessonStorageJSON)));
        searchFilterGroup();
    }, []);
    useEffect(function () {
    }, [selectedRow]);
    // when change pagination
    var handlePagination = function (page, perPage) {
        searchFilterGroup(page, perPage);
        setSelectedAll(false);
    };
    // filter group when "search"
    var searchFilterGroup = function (page, perPage) {
        var _a;
        setIsLoading(true);
        var sessonStorageJSON = (_a = JSON.parse(sessionStorage.getItem("".concat(window.location.pathname, "_filter")))) !== null && _a !== void 0 ? _a : {};
        var params = {
            pagination: { page: page !== null && page !== void 0 ? page : 1, perPage: perPage !== null && perPage !== void 0 ? perPage : 10 },
            sort: { field: "updatedAt", order: "DESC" },
            // filter: updatedFilters //[back][0715] resume this
            filter: filters ? __assign(__assign({}, filters), sessonStorageJSON) : null
        };
        dataProvider
            .getList(props, params)
            .then(function (data) {
            setTotal(data.total);
            if (data.total === 0) {
                setCount(1);
            }
            var updatedData = data.data;
            // set "selected" for bulk action
            updatedData.map(function (el, idx) {
                el.selected = false;
            });
            // let headerData = {
            //     "id": translate(`pages.subject.fields.id`),
            //     "customId": translate(`pages.subject.fields.customId`),
            //     "firstname": translate(`pages.subject.fields.firstname`),
            //     "lastname": translate(`pages.subject.fields.lastname`),
            //     "gender": translate(`pages.subject.fields.gender`),
            //     "educationLevel": translate(`pages.subject.fields.educationLevel`),
            //     "dateOfBirth": translate(`pages.subject.fields.dateOfBirth`),
            //     "isHeader": true,
            //     "selected": false
            // }
            // // setData([...headerData, ...updatedData]);
            // updatedData.unshift(headerData);
            setData(updatedData);
            // refresh()
            notify(translate("pages.subject.notify.get"));
            setIsLoading(false);
        })
            .catch(function (error) {
            // Handle any errors that occurred
            console.error(error);
            setIsLoading(false);
            notify(translate("pages.subject.notify.getFailed"), {
                type: 'error',
            });
        });
    };
    useEffect(function () {
        var ndata = __spreadArray([], data, true);
        setData([]);
        setData(ndata);
        setTimeout(function () {
        }, 150);
    }, [selectedAll]);
    // handle selection
    var handleBulkSelection = function (isHeader, record, selected, checked) {
        var _a, _b, _c;
        var updatedData = JSON.parse(JSON.stringify(data));
        if (isHeader) {
            var updatedDataGridHeader = __spreadArray([], dataGridHeader, true);
            setSelectedAll(checked);
            switch (checked) {
                case true:
                    var appBarOrg_1 = (_a = localStorage.getItem("appBarOrg")) !== null && _a !== void 0 ? _a : "";
                    if ((_b = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.data_export_admin) === null || _b === void 0 ? void 0 : _b.export) {
                        updatedData.map(function (el, idx) {
                            el.selected = true;
                        });
                        setHasBulkSelect(true);
                    }
                    //check if only can export own org
                    else if ((_c = acessControlList === null || acessControlList === void 0 ? void 0 : acessControlList.data_export_normal) === null || _c === void 0 ? void 0 : _c.export) {
                        var tempHasBulkSelect_1 = false;
                        updatedData.map(function (el, idx) {
                            var _a;
                            if (((_a = el === null || el === void 0 ? void 0 : el.organization) === null || _a === void 0 ? void 0 : _a.orgName) === appBarOrg_1) {
                                el.selected = true;
                                tempHasBulkSelect_1 = true;
                            }
                            else {
                                el.selected = false;
                            }
                        });
                        setHasBulkSelect(tempHasBulkSelect_1);
                    }
                    break;
                default:
                    updatedData.map(function (el, idx) {
                        el.selected = false;
                    });
                    setHasBulkSelect(false);
                    break;
            }
            setData([]);
            setData(updatedData);
            updatedDataGridHeader[0].selected = checked;
            setDataGridHeader(updatedDataGridHeader);
        }
        else {
            var selectedIndex = updatedData.findIndex(function (item) { return item.id === record.id; });
            switch (selected) {
                case true:
                    updatedData[selectedIndex]["selected"] = false;
                    var currentHasBulkSelect_1 = false;
                    updatedData.map(function (el, idx) {
                        if (el.selected) {
                            currentHasBulkSelect_1 = true;
                        }
                    });
                    setHasBulkSelect(currentHasBulkSelect_1);
                    break;
                default:
                    updatedData[selectedIndex]["selected"] = true;
                    setHasBulkSelect(true);
                    break;
            }
        }
        setData(updatedData);
    };
    return (_jsx(Grid, { container: true, columnGap: NORMAL_GRID_SPACE, rowGap: 2, direction: { xs: "row", lg: "column" }, children: _jsxs(Grid, { container: true, xs: 12, direction: "row", rowGap: 1, children: [_jsx(Grid, { xs: 12, children: _jsx(general.breadcrumb, { trail: [
                            translate("breadcrumb.main"),
                            translate("pages.dataExport.name"),
                        ] }) }), _jsx(Grid, { container: true, display: "flex", alignItems: "center", justifyContent: "start", direction: "row", columnGap: NORMAL_GRID_SPACE, children: _jsx(Grid, { children: _jsx(Typography, { className: "normal-text large-bold", children: translate("pages.dataExport.name") }) }) }), _jsx(Grid, { xs: 12, children: _jsx(Divider, {}) }), _jsx(Grid, { xs: 12, marginBottom: 2, children: _jsx(FilterList, { translate: translate, search: searchFilterGroup, isLoading: isLoading, params: params, filters: filters, setFilters: setFilters }) }), _jsx(Grid, { xs: 12, children: _jsxs(Grid, { item: true, position: "relative", children: [_jsx(Grid, { children: _jsx(Button, { variant: "text", sx: { backgroundColor: "white", fontSize: "0.875rem" }, onClick: function () {
                                        handleBulkSelection(true, selectedAll, selectedAll, !selectedAll);
                                    }, children: selectedAll
                                        ? translate("common.unselectAll")
                                        : translate("common.selectAll") }) }), _jsx(TableDatagrid, { selectedAll: selectedAll, setSelectedAll: setSelectedAll, dataGridHeader: dataGridHeader, translate: translate, isLoading: isLoading, data: data, setData: setData, educationLevelArray: educationLevelArray, handleBulkSelection: handleBulkSelection, previewExport: previewExport, dataProvider: dataProvider, refresh: refresh, notify: notify, resource: resource, navigate: navigate, acessControlList: acessControlList }), _jsx(PaginationControlled, { searchFilterGroup: handlePagination, total: total, setTotal: setTotal, count: count, setCount: setCount, rowsPerPage: rowsPerPage, setRowsPerPage: setRowsPerPage })] }) }), _jsx(Grid, { xs: 12, display: "flex", justifyContent: "end", children: hasBulkSelect && (_jsxs(_Fragment, { children: [(acessControlList.data_export_normal.export || acessControlList.data_export_admin.export) && (_jsx(Button, { variant: "contained", onClick: function () { return previewExport(true); }, className: "button-all", sx: { marginRight: 2 }, children: translate("dialog.exportSubjects") })), _jsx(Button, { variant: "contained", onClick: function () { return previewExport(); }, className: "button-all", children: translate("dialog.export") })] })) })] }) }));
};
export default ExportList;
