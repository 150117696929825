var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDataProvider, useNotify, useRefresh, useTranslate, } from "react-admin";
import { Typography, Grid, } from "@mui/material";
import ImageDrop from "../../utils/ImageUpload/ImageDrop";
import { blobToURL } from "../../utils/fileUploadComp";
import { updateAnalyzedResultTextCases } from "../fp/helper/FPhelper";
var styles = {
    documentBlock: {
        width: 160,
        height: 200,
        margin: "20px auto",
        marginTop: 8,
        overflow: "hidden",
    },
    controls: {
        maxWidth: 160,
        margin: "0 auto",
        marginTop: 8,
    },
    container: {
        width: "100%",
        height: "42rem",
        // backgroundColor: "#fafbfc"
        backgroundColor: "white",
    },
    containerAfterAnlyze: {
        width: "100%",
        height: "65vh",
    },
    loadingContainer: {
        textAlign: "center",
        padding: 20,
        marginTop: 12,
        color: "black",
        fontSize: 18,
        fontWeight: 600,
        borderRadius: 4,
        userSelect: "none",
        outline: 0,
        cursor: "pointer",
        width: "100%",
        // backgroundColor: "blue"
    },
};
var DataImport = function (_a) {
    var onLoaded = _a.onLoaded, image = _a.image, setImage = _a.setImage, imageInfos = _a.imageInfos, setImageInfos = _a.setImageInfos, analyzedResultInfo = _a.analyzedResultInfo, setAnalyzedResultInfo = _a.setAnalyzedResultInfo, analyzedImagesData = _a.analyzedImagesData, setAnalyzedImagesData = _a.setAnalyzedImagesData, isLoadingUpload = _a.isLoadingUpload, setIsLoadingUpload = _a.setIsLoadingUpload, currentImgUploadRow = _a.currentImgUploadRow, setCurRowImgs = _a.setCurRowImgs, haveUploads = _a.haveUploads, curRowImgs = _a.curRowImgs;
    var dataProvider = useDataProvider();
    var notify = useNotify();
    var refresh = useRefresh();
    var resource = "exams";
    var translate = useTranslate();
    //change text and containers if haveuploads
    // get uploaded image infos
    var handleUploadedImages = function (files, blobbedFiles) {
        var formData = new FormData();
        files.map(function (el, idx) {
            formData.append("images", el);
        });
        var params = {
            data: {
                id: currentImgUploadRow.id, // exam id
                subject_customId: currentImgUploadRow.subject_customId, // subjct custom id
                parentGivenId: currentImgUploadRow.hkid_id, //subject id
                givenId: currentImgUploadRow.id,
                formData: formData,
            },
        };
        //[back] is below api
        dataProvider
            .fpUpload(resource, params)
            .then(function (rtnData) {
            setIsLoadingUpload(true);
            console.log(rtnData.data, "==log rtnData");
            notify(translate("fp.notify.uploaded"));
            setCurRowImgs(rtnData.data.fp_imgs);
            setImageInfos(files[0]);
            setImage(blobbedFiles[0]);
            var eyeLabel = updateAnalyzedResultTextCases(rtnData.data.fp_imgs, setAnalyzedResultInfo, translate);
            //Params for update ai_model and eye
            var editParams = {
                data: {
                    subject_customId: currentImgUploadRow.subject_customId,
                    ai_model: "FP",
                },
            };
            // update eye if gradable
            if (eyeLabel) {
                editParams.data.eye = eyeLabel;
            }
            return dataProvider.updateAttribute(resource, editParams, currentImgUploadRow.hkid_id, currentImgUploadRow.id);
        })
            .then(function () {
            refresh();
            setIsLoadingUpload(false);
        })
            .catch(function (error) {
            // Handle any errors that occurred
            console.error(error);
            if (err == "HttpError2: Exam not found") {
                notify(translate('error.fp.examNotFound'), {
                    type: 'error',
                });
            }
            else {
                notify(translate("error.fp.uploadFailed"), {
                    type: 'error',
                });
            }
            // setIsLoadingUpload(false)
        });
    };
    return (_jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, display: "flex", flexWrap: "wrap", justifyContent: "space-between", children: _jsxs(Grid, { item: true, children: [_jsx(Typography, { className: "normal-text normal-bold", color: "grey", children: translate("fp.fundusPhotos") }), _jsx(Typography, { className: "small-text", color: "grey", children: translate("fp.pleaseUploadTheFundusPhotos") })] }) }), !isLoadingUpload && (_jsx(Grid, { item: true, xs: 12, style: __assign(__assign({}, styles.container), { height: haveUploads ? "9rem" : "45rem" }), children: _jsx(ImageDrop, { isLoadingUpload: isLoadingUpload, setIsLoadingUpload: setIsLoadingUpload, haveUploads: haveUploads, currentImgUploadRow: currentImgUploadRow, curRowImgs: curRowImgs, onLoaded: function (files) { return __awaiter(void 0, void 0, void 0, function () {
                        var blobbedFiles, _i, files_1, file, URL_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(files.length > 0)) return [3 /*break*/, 5];
                                    blobbedFiles = [];
                                    _i = 0, files_1 = files;
                                    _a.label = 1;
                                case 1:
                                    if (!(_i < files_1.length)) return [3 /*break*/, 4];
                                    file = files_1[_i];
                                    return [4 /*yield*/, blobToURL(file)];
                                case 2:
                                    URL_1 = _a.sent();
                                    blobbedFiles.push(URL_1);
                                    _a.label = 3;
                                case 3:
                                    _i++;
                                    return [3 /*break*/, 1];
                                case 4:
                                    handleUploadedImages(files, blobbedFiles);
                                    _a.label = 5;
                                case 5: return [2 /*return*/];
                            }
                        });
                    }); } }) }))] }));
};
export default DataImport;
